.cp {
    display: flex;
    flex-direction: column;
    background: scroll;
    background-color: white;
    /* background-image: radial-gradient(39% 90% at top left,#8df0d9 55%,#abcfca 92%, transparent 90%); */
    position: relative;
}

.cp .badge {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    width: 200px;
    color: #000080;
    /* Dark blue text color */
    background: linear-gradient(180deg, #ffa64d, #cc6600);
    /* Gradient from light orange to dark orange */
    position: relative;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-self: center;

}


/* .cp .badge:after {
    content: '';
    position: absolute;
    top: 0;
    right: -19px; 
    width: 0;
    height: 0;
    border-left: 20px solid #cc6600; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
} */

.cp .cpbadge {
    width: 512px;
    height: 80px;
}

.cp .image {
    width: 80px;
    height: 80px;
    /* object-fit: cover;  */
    /* display: block; */
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.cp .p5 {
    margin-top: -3px;
    margin-bottom: -3px;
    font-size: 14px;
}