@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');

* {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* transition: .2s linear; */
}

.portfolio-page {
    min-height: 90vh;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    color: white;
    position: relative;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.content-container {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    /* Increased from default 800px */
    padding: 0 20px;
}

.animated-text {
    font-family: 'Poppins', sans-serif;
    animation: fadeInUp 1s ease;
}

.name-highlight {
    color: #ffd700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.role-text {
    /* font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    animation: fadeInUp 1s ease 0.2s;
    animation-fill-mode: backwards; */
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    animation: fadeInUp 1s ease 0.2s;
    animation-fill-mode: backwards;
    white-space: nowrap;
    /* Prevent text wrapping */
    font-size: 2.5rem;
    /* Increased base font size */
}

.description-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    max-width: 800px;
    margin: 0 auto;
    animation: fadeInUp 1s ease 0.4s;
    animation-fill-mode: backwards;
}

.description-text p {
    margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .role-text {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .animated-text {
        font-size: 3.5rem;
    }

    .role-text {
        font-size: 1.75rem;
        white-space: normal;
        /* Allow wrapping on very small screens */
    }

    .description-text {
        font-size: 1rem;
        padding: 0 15px;
        white-space: normal;
    }
}

@media (max-width: 480px) {
    .animated-text {
        font-size: 2.5rem;
    }

    .role-text {
        font-size: 1.25rem;
    }
}

.cta-buttons {
    margin-top: 3%;
    animation: fadeInUp 1s ease 0.4s;
    animation-fill-mode: backwards;
}

.btn-outline-light:hover {
    transform: translateY(-3px);
    transition: all 0.3s ease;
}

.social-links {
    position: absolute;
    left: 30px;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.social-icon {
    color: white;
    font-size: 1.5rem;
    transition: all 0.3s ease;
}

.social-icon:hover {
    transform: translateY(-3px);
    color: #ffd700;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.rounded-circle {
    border: 4px solid white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.badge {
    padding: 0.6em 1em;
    font-size: 0.9em;
    letter-spacing: 0.5px;
}

section {
    padding: 2rem 9%;
}

.home {
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
    /* min-height: 100vh; */
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    /* background-image: radial-gradient(50% 70% at top left, #abcfca 55%, #8df0d9 92%, transparent 90%),
        radial-gradient(70% 50% at bottom right, #8df0d9 55%, #abcfca 92%, transparent 90%);
    background-repeat: no-repeat; */
}

.home .content {
    flex: 1 1 40rem;
}

.home .content .name {
    padding-top: -10rem;
    font-size: 3rem;
    margin-top: 5%;
    color: #2a201c;
}

.home .content .title {
    /* font-size: 1.6rem; */
    /* color: #fff; */
    color: #000;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.home .content .description {
    /* color: #fff; */
    color: #000;
    font-weight: normal;
    line-height: 1.8;
    padding: 1.1rem 0;
}

.home .content .title span {
    display: inline;
    color: #d3ad7f;
}

.home .content .description span {
    color: #ec2308;
}

.home .image img {
    transition: transform 0.3s ease;
    border: 10px solid white;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.home .image img:hover {
    transform: scale(1.2);
}

#typing span {
    color: black;
    display: inline;
}

#typing .typing-cursor {
    display: inline-block;
    width: 2px;
    /* Slim vertical bar as cursor */
    height: 0.1em;
    background-color: black;
    animation: blink 0.7s infinite;
    margin-left: 4px;
}